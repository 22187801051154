import React from 'react';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import styled, { css } from 'styled-components';
import { darken, math } from 'polished';
import queryString from 'query-string';
import { FiMenu } from 'react-icons/fi';
import { FaBoxOpen } from 'react-icons/fa';

import useAuth from '../../utils/useAuth';
import { hideAt } from '../../utils/responsive';
import { isBrowser } from '../../utils/browser';
import breakpoints from '../../styles/breakpoints';
import { ENTER } from '../../constants/keys';
import logo from '../../images/logo.png';
import smallLogo from '../../images/logo-small.png';
import SearchBox from '../SearchBox';
import Dropdown from '../Dropdown';
import UserDropdown from './UserDropdown';
import SubjectDropdown from './SubjectDropdown';

const SmallScreen = hideAt({ min: math(`${breakpoints.md} + 1px`) });
const BigScreen = hideAt({ max: breakpoints.md });

const Wrapper = styled.header`
  border-bottom: solid 1px #eceff0;
`;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1440px;
  height: 70px;
`;

const RightPanel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const HomeLink = styled(Link).attrs({ to: '/' })`
  display: inline-block;
  line-height: 0;
  text-decoration: none;
`;

const Logo = styled.img.attrs({ alt: 'Trang Logo' })`
  margin: 0 1rem 0 0;
  height: 44px;
`;

const GlobalSearch = styled(SearchBox).attrs({ placeholder: 'Tìm kiếm' })`
  width: 400px;

  @media (max-width: 950px) {
    width: auto;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;

const linkButton = css`
  display: inline-block;
  padding: 0 1rem;
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
`;

const SignupLink = styled(Link).attrs({ to: '/signup/' })`
  ${linkButton};
  background: #10abe2;
  color: #ffffff;
  font-size: 0.875rem;

  &:hover {
    background: ${darken(0.04, '#10abe2')};
    color: #ffffff;
  }
`;

const LoginLink = styled(Link).attrs({ to: '/login/' })`
  ${linkButton};
  margin: 0 0.375rem 0 1rem;
  border: 1px solid #b7b5bd;
  color: #78757a;
  font-size: 0.875rem;

  &:hover {
    border-color: ${darken(0.04, '#b7b5bd')};
    color: ${darken(0.1, '#78757a')};
  }
`;

const PurchaseLink = styled(LoginLink).attrs({ to: '/purchase-subscription/' })`
  margin: 0 0 0 1rem;
  border-radius: 50%;
  width: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const MenuIcon = styled(FiMenu)`
  font-size: 1.5rem;
  margin-left: 1rem;
  cursor: pointer;
`;

const AuthMenu = styled.div`
  padding: 0.8rem;

  ${LoginLink},
  ${SignupLink} {
    width: 100%;
    margin: 0.3rem 0;
    text-align: center;
  }
`;

function Header() {
  const { user } = useAuth();
  const { q } = queryString.parse(isBrowser() ? window.location.search : '');

  const authInfo = user ? (
    <UserDropdown />
  ) : (
    <>
      <BigScreen>
        <LoginLink>Đăng nhập</LoginLink>
        <SignupLink>Đăng ký</SignupLink>
      </BigScreen>
      <SmallScreen>
        <Dropdown trigger={<MenuIcon />} position="right" triggerHeight="54px">
          <AuthMenu>
            <LoginLink>Đăng nhập</LoginLink>
            <SignupLink>Đăng ký</SignupLink>
          </AuthMenu>
        </Dropdown>
      </SmallScreen>
    </>
  );

  return (
    <Wrapper>
      <MainContainer>
        <HomeLink>
          <BigScreen>
            <Logo src={logo} />
          </BigScreen>
          <SmallScreen>
            <Logo src={smallLogo} />
          </SmallScreen>
        </HomeLink>
        <SubjectDropdown />
        <RightPanel>
          <GlobalSearch
            defaultValue={q}
            onKeyDown={event => {
              if (event.keyCode === ENTER) {
                navigate(`/search?q=${event.target.value}`);
              }
            }}
          />
          <PurchaseLink>
            <FaBoxOpen />
          </PurchaseLink>
          {isBrowser() && authInfo}
        </RightPanel>
      </MainContainer>
    </Wrapper>
  );
}

export default Header;
