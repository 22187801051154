import { request } from '../utils/http';

export function getAllVideos() {
  return request({
    apiVersion: '1.2',
    url: '/videos',
    method: 'GET',
  });
}

export function getVideosBySubject({ subject, level, page = 1, limit = 10 }) {
  return request({
    url: `subject/${subject}/videos`,
    method: 'GET',
    params: {
      level,
      page,
      limit,
    },
  });
}

export function getMyVideos({ subjectCode, page = 1, limit = 10 }) {
  return request({
    url: 'home/me',
    method: 'GET',
    params: {
      subjectCode,
      page,
      limit,
    },
    authRequired: true,
  });
}

export function searchVideos(q, { page = 1, limit = 20 }) {
  return request({
    apiVersion: '1.3',
    url: 'video/search',
    method: 'GET',
    params: {
      q,
      page,
      limit,
    },
  });
}

export function getVideo(id) {
  return request({
    apiVersion: '1.2',
    url: `/video/${id}`,
    method: 'GET',
    authRequired: true,
  });
}

export function markViewed(id) {
  return request({
    url: `/video/${id}/viewed`,
    method: 'PUT',
    authRequired: true,
  });
}

export function markDemoViewed(id) {
  return request({
    url: `/video/${id}/demo-viewed`,
    method: 'PUT',
  });
}

export function addFavoriteVideo(id) {
  return request({
    apiVersion: '1.2',
    url: `/video/${id}/favorite`,
    method: 'PUT',
    authRequired: true,
  });
}

export function removeFavoriteVideo(id) {
  return request({
    apiVersion: '1.2',
    url: `/video/${id}/unfavorite`,
    method: 'PUT',
    authRequired: true,
  });
}

export function checkFavoriteVideo(id) {
  return request({
    apiVersion: '1.2',
    url: `/video/${id}/favorite`,
    method: 'GET',
    authRequired: true,
  });
}

export function getFavoriteVideos() {
  return request({
    apiVersion: '1.2',
    url: `/videos/favorite`,
    method: 'GET',
    authRequired: true,
  });
}

export function checkDownloadUrl(videoCode) {
  return request({
    apiVersion: '1.3',
    url: `/video/${videoCode}/download`,
    method: 'GET',
    authRequired: true,
  });
}

export function getDownloadUrl({ captcha, videoCode }) {
  return request({
    apiVersion: '1.2',
    url: `/video/${videoCode}/download`,
    method: 'POST',
    authRequired: true,
    data: { captcha, videoCode },
  });
}

export function getLevelsSubjectsHasVideos() {
  return request({
    apiVersion: '1.2',
    url: `/levels/subjects/has-videos`,
    method: 'GET',
  });
}
