import React from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaYoutube } from 'react-icons/fa';
import { Link } from 'gatsby';

import breakpoints from '../../styles/breakpoints';
import Delimiter from '../Delimiter';

const Wrapper = styled.footer`
  border-top: solid 1px #eceff0;
`;

const MainContainer = styled.div`
  margin: 0 auto;
  padding: 2rem 1rem 70px 1rem;
  max-width: 1440px;
  color: #7e7e7e;
`;

const Menu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const MenuItem = styled.li`
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  font-weight: 600;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }

  a {
    color: #7e7e7e;
    text-decoration: none;

    &:hover {
      color: #2e2e2e;
    }
  }
`;

const Top = styled.div`
  display: flex;
  margin-bottom: 2rem;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

const TopTitle = styled.h3`
  font-size: 1.125rem;
  font-style: bold;
  margin-bottom: 1rem;
`;

const AboutUs = styled.div`
  width: 28rem;
  margin-right: 1rem;

  p {
    margin: 0;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: auto;
    margin: 0 0 1.5rem 0;
  }
`;

const Contact = styled.div`
  width: 24rem;

  @media (max-width: ${breakpoints.sm}) {
    width: auto;
  }
`;

const Bottom = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

const Copyright = styled.span`
  flex: 1;
  color: #7e7e7e;

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 10px;
  }
`;

const SocialButtons = styled.div`
  display: flex;
  align-items: center;
`;

const SocialButton = styled.a.attrs({ target: '_blank', rel: 'noopener noreferrer' })`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  color: #637076;
  background: #eceff0;
  border-radius: 50%;

  & + & {
    margin-left: 10px;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <MainContainer>
        <Top>
          <AboutUs>
            <TopTitle>trang.edu.vn</TopTitle>
            <p>
              Ngân hàng video giáo dục Trạng là một dự án khởi nghiệp của những người bạn trẻ giáo
              viên, kỹ sư, thiết kế đồ hoạ, kinh tế,… mong muốn
              <em> Mang thế giới vào trong lớp học </em>
              để hỗ trợ thầy cô và học sinh được học tập vui thích hàng ngày.
            </p>
          </AboutUs>
          <Contact>
            <TopTitle>Liên hệ</TopTitle>
            <div>Địa chỉ: 02-04 Alexandre de Rhodes, P. Bến Nghé, Q.1, TP. Hồ Chí Minh</div>
            <div>
              <span>Email: </span>
              <a href="mailto:videogiaoduc@trang.edu.vn">videogiaoduc@trang.edu.vn</a>
            </div>
            <div>
              <span>Điện thoại: </span>
              <a href="tel:+84909910583">+84909910583</a>
            </div>
          </Contact>
        </Top>
        <Menu>
          <MenuItem>
            <a href="/about">Giới thiệu</a>
          </MenuItem>
          <MenuItem>
            <Link to="/contact">Trợ giúp</Link>
          </MenuItem>
          <MenuItem>
            <a href="/copyright">Bản quyền</a>
          </MenuItem>
          <MenuItem>
            <a href="/promos">Khuyến mãi</a>
          </MenuItem>
          <MenuItem>
            <a href="/blogs">Bài viết</a>
          </MenuItem>
        </Menu>
        <Delimiter />
        <Bottom>
          <Copyright>{`Bản quyền thuộc về Video Giáo dục Trạng © ${new Date().getFullYear()}`}</Copyright>
          <SocialButtons>
            <SocialButton href="//www.facebook.com/trang.edu.vn/">
              <FaFacebookF />
            </SocialButton>
            <SocialButton href="//www.youtube.com/channel/UCgS7WSdW6JRXCEYrv4gfA3w?view_as=subscriber">
              <FaYoutube />
            </SocialButton>
          </SocialButtons>
        </Bottom>
      </MainContainer>
    </Wrapper>
  );
}

export default Footer;
