export const CATEGORY = Object.freeze({
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  VIDEO: 'VIDEO',
});

export const ACTION = Object.freeze({
  SIGN_UP: 'Sign up',
  ACTIVATE_ACCOUNT_SUCCESSFUL: 'Activate account successful',
  ACTIVATE_ACCOUNT_FAILED: 'Activate account failed',
  LOGIN_SUCCESSFUL: 'Login successful',
  LOGIN_FAILED: 'Login failed',
  FORGOT_PASSWORD: 'Forgot password',
  SUBMIT_CONTACT_FORM: 'Submit contact form',
  PURCHASE_SUBSCRIPTION: 'Purchase subscription',
  UPDATE_PROFILE: 'Update profile',
  CHANGE_PASSWORD: 'Change password',
  FILTER_BY_SUBJECT: 'Filter by subject',
  FILTER_BY_LEVEL: 'Filter by level',
  VIEW_MORE_BY_SUBJECT: 'View more videos by subject',
  SEARCH: 'Search',
  VIEW_PURCHASED_VIDEO: 'View purchased video',
  VIEW_DEMO_VIDEO: 'View demo video',
  OPEN_VIDEO: 'Open video',
  GO_TO_PURCHASE: 'Go to purchase',
  USE_TABLE_OF_CONTENTS: 'Use table of contents',
  USE_BRUSH_TOOL: 'Use brush tool',
  ADD_FAVORITE_VIDEO: 'Add favorite video',
  REMOVE_FAVORITE_VIDEO: 'Add favorite video',
});

export const LABEL = Object.freeze({
  PAGE_HEADER: 'Page header',
  HOME_PAGE: 'Home page',
  HOME_PAGE_NEW_RELEASES: 'Home page - New releases',
  HOME_PAGE_SUBJECT_VIDEOS: 'Home page - Subject videos',
  MY_VIDEO_PAGE: 'My video page',
  VIDEO_PAGE: 'Video page',
  VIDEO_PAGE_NEXT_VIDEOS: 'Video page - Next videos',
  VIDEO_PAGE_RELATED_VIDEOS: 'Video page - Related videos',
  SUBJECT_PAGE: 'Subject page',
  SEARCH_PAGE: 'Search page',
});
