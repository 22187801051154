import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { MdApps } from 'react-icons/md';
import ReactGA from 'react-ga';

import * as API from '../../apis';
import breakpoints from '../../styles/breakpoints';
import { ACTION, CATEGORY, LABEL } from '../../constants/ga';
import Dropdown from '../Dropdown';
import Menu from '../Menu';
import Spinner from '../Spinner';

const StyledDropdown = styled(Dropdown).attrs({
  menuClassName: 'menu',
})`
  margin: 0 1rem;
  display: inline-flex;
  align-items: center;

  .menu {
    top: 32px;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-left: 0;
  }
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  color: #78757a;
`;

const SubjectIcon = styled(MdApps)`
  margin-right: 0.375rem;
  font-size: 1.5rem;
`;

const Label = styled.span`
  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

function SubjectDropdown(props) {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchSubjects() {
      try {
        setLoading(true);
        const response = await API.getTopLevelSubjects();
        setLoading(false);
        setSubjects(response.subjects);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }

    fetchSubjects();
  }, []);

  const trigger = (
    <Trigger>
      <SubjectIcon />
      <Label>Môn học</Label>
    </Trigger>
  );

  const ga = subjectCode => () => {
    ReactGA.event({
      category: CATEGORY.VIDEO,
      action: ACTION.FILTER_BY_SUBJECT,
      label: LABEL.PAGE_HEADER,
      value: subjectCode,
    });
  };

  return (
    <StyledDropdown trigger={trigger} position="left" {...props}>
      <Menu>
        {subjects.map(subject => (
          <Menu.Item
            key={subject.code}
            as={Link}
            to={`/subjects/${subject.code}`}
            onClick={ga(subject.code)}
          >
            {subject.name}
          </Menu.Item>
        ))}
      </Menu>
      {loading && <Spinner />}
    </StyledDropdown>
  );
}

export default SubjectDropdown;
