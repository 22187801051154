import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import _ from 'lodash';
import moment from 'moment';
import ReactConfirmAlert from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import * as API from '../apis';
import breakpoints from '../styles/breakpoints';
import { ENTER } from '../constants/keys';
import { ACTION, CATEGORY } from '../constants/ga';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Input from '../components/Input';
import Button from '../components/Button';
import Message from '../components/Message';
import PromoList from '../components/PromoList';
import Checkbox from '../components/Checkbox';

const MainContent = styled.div`
  min-height: 100vh;
  background: rgb(245, 247, 250);
`;

const Card = styled.div`
  max-width: 570px;
  margin: 0 auto;
  padding: 5rem 1rem;
  ${props => props.loading && `pointer-events: none;`}

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 1rem;
  }
`;

const Header = styled.header`
  padding: 2.5rem;
  font-size: 1.375rem;
  font-weight: 500;
  text-align: center;
  color: #343434;
  background-color: #ffffff;
  border-bottom: solid 1px #eceff0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }
`;

const Body = styled.section`
  padding: 1.5rem 4.375rem 4.375rem;
  text-align: left;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 2rem 2rem;
  }
`;

const PromoHeader = styled.section`
  padding: 2rem 0 1.5rem;
  font-weight: 600;
  text-align: left;
  background-color: #ffffff;
`;

const Field = styled.div`
  margin-bottom: 1.5rem;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 1.5rem 0;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const SignupButton = styled(Button)`
  padding-left: 4.25rem;
  padding-right: 4.25rem;
  margin-right: 1.5rem;

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    margin: 0 0 1rem 0;
    justify-content: center;
  }
`;

const LoginLink = styled(Link)`
  font-weight: 500;

  @media (max-width: ${breakpoints.sm}) {
    align-self: flex-end;
  }
`;

const TermsLink = styled.a`
  display: block;
  margin-top: 5px;
  margin-left: 40px;
`;

function SignupPage({ data }) {
  const { allTrangPromoNodes } = data;
  const allTrangPromo = allTrangPromoNodes.edges.map(({ node }) => node);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [agreeTerm, setAgreeTerm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isShowConfirmDialog, showConfirmDialog] = useState(false);
  const invalid = !email || !password || !passwordConfirm || passwordNotMatch;

  const handleRegister = async () => {
    if (invalid || loading) {
      return;
    }

    try {
      setLoading(true);
      setErrorMessage('');
      await API.register(email, password, promoCode);
      setLoading(false);
      setSuccessMessage(
        'Đăng ký tài khoản thành công.<br />Vui lòng <b>kiểm tra hộp thư</b> của bạn để <b>kích hoạt tài khoản</b>.'
      );
      setEmail('');
      setPassword('');
      setPasswordConfirm('');
      setPromoCode('');
      setAgreeTerm(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Có lỗi xảy ra. Vui lòng thử lại sau.');
      }
      console.error(error);
    }

    ReactGA.event({
      category: CATEGORY.USER_MANAGEMENT,
      action: ACTION.SIGN_UP,
    });
  };

  const promos = _.filter(allTrangPromo, promo => {
    return (
      ((!_.isNil(promo.pricing12month) && promo.pricing12month === 0) ||
        (!_.isNil(promo.pricing5month) && promo.pricing5month === 0) ||
        (!_.isNil(promo.pricing3month) && promo.pricing3month === 0)) &&
      moment(promo.validFrom).isSameOrBefore(moment()) &&
      moment(promo.validUntil).isSameOrAfter(moment()) &&
      promo.appliedFor === 'signup'
    );
  });

  return (
    <Layout mainContent={MainContent}>
      <SEO title="Đăng ký tài khoản" />
      <Card loading={loading}>
        <Header>Đăng ký tài khoản</Header>
        <Body>
          <Field>
            <Input
              label="Email"
              placeholder="Địa chỉ email của bạn"
              value={email}
              onChange={event => setEmail(event.target.value)}
              onKeyDown={event => {
                if (event.keyCode === ENTER) {
                  handleRegister();
                }
              }}
            />
          </Field>
          <Field>
            <Input
              type="password"
              label="Mật khẩu"
              placeholder="**********"
              value={password}
              hasError={passwordNotMatch}
              message={passwordNotMatch ? 'Mật khẩu không khớp' : ''}
              onChange={event => {
                const { value } = event.target;
                setPassword(value);
                if (passwordConfirm && value !== passwordConfirm) {
                  setPasswordNotMatch(true);
                } else {
                  setPasswordNotMatch(false);
                }
              }}
              onKeyDown={event => {
                if (event.keyCode === ENTER) {
                  handleRegister();
                }
              }}
            />
          </Field>
          <Field>
            <Input
              type="password"
              label="Nhập lại mật khẩu"
              placeholder="**********"
              value={passwordConfirm}
              hasError={passwordNotMatch}
              message={passwordNotMatch ? 'Nhập lại mật khẩu không khớp' : ''}
              onChange={event => {
                const { value } = event.target;
                setPasswordConfirm(value);
                if (password && value !== password) {
                  setPasswordNotMatch(true);
                } else {
                  setPasswordNotMatch(false);
                }
              }}
              onKeyDown={event => {
                if (event.keyCode === ENTER) {
                  handleRegister();
                }
              }}
            />
          </Field>
          {promos.length > 0 && (
            <>
              <PromoHeader>Bạn có thể chọn một trong những khuyến mãi sau:</PromoHeader>
              <PromoList
                promos={promos}
                promoCode={promoCode}
                selectable
                onSelected={p => setPromoCode(p)}
              />
              <br />
              <br />
              <br />
            </>
          )}
          <Checkbox
            label="Tôi đồng ý với các điều khoản của Trạng"
            checked={agreeTerm}
            onClick={() => setAgreeTerm(!agreeTerm)}
          />
          <TermsLink href="/copyright/" target="_blank">
            Xem các điều khoản
          </TermsLink>
          <Actions>
            <SignupButton
              size="large"
              loading={loading}
              disabled={!agreeTerm || invalid || loading}
              onClick={() =>
                promos.length > 0 && !promoCode ? showConfirmDialog(true) : handleRegister()
              }
            >
              Đăng ký
            </SignupButton>
            <LoginLink to="/login/">Đăng nhập</LoginLink>
          </Actions>
          <br />
          <br />
          Bạn cần hỗ trợ? Liên lạc ngay:&nbsp;
          <a href="tel:+84909910583">+84909910583</a>
          {errorMessage && <Message error>{`* ${errorMessage}`}</Message>}
          {successMessage && (
            <Message
              dangerouslySetInnerHTML={{
                __html: successMessage,
              }}
            />
          )}
        </Body>
      </Card>
      {isShowConfirmDialog && (
        <ReactConfirmAlert
          title="Lưu ý! Bạn chưa chọn khuyến mãi nào."
          message="Bạn có muốn tiếp tục hoàn tất đăng ký?"
          buttons={[
            {
              label: 'Chọn khuyến mãi',
              onClick: () => {
                showConfirmDialog(false);
              },
            },
            {
              label: 'Hoàn tất đăng ký',
              onClick: () => {
                handleRegister();
                showConfirmDialog(false);
              },
            },
          ]}
        />
      )}
    </Layout>
  );
}

SignupPage.propTypes = {
  data: PropTypes.shape({
    allTrangPromoNodes: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default SignupPage;

export const pageQuery = graphql`
  query {
    allTrangPromoNodes: allTrangPromo {
      edges {
        node {
          slug
          code
          title
          appliedFor
          state
          subscription {
            name
            code
            pricing12month
            pricing3month
            pricing5month
          }
          content {
            brief
            extended
          }
          pinnedToHomepage
          validFrom
          validUntil
          pricing12month
          pricing3month
          pricing5month
        }
      }
    }
  }
`;
