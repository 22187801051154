import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';

import Checkbox from '../Checkbox';

const Container = styled.div`
  ${props => props.selectable && `cursor: pointer;`}
  clear: both;
  color: #2e2e2e;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
`;
const Bullet = styled.span`
  margin-right: 10px;
`;

const Pricing = styled.div`
  display: inline;
`;

const Field = styled.span`
  display: inline-block;
  margin-right: 5px;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  margin-right: 10px;
`;

const NewPrice = styled.span`
  color: red;
  font-weight: bold;
`;

const Title = styled.span`
  margin-right: 5px;
`;

const PricingPromoContainer = styled.div`
  display: ${props => (props.listType === 'newline' ? 'block' : 'inline')};
  margin-right: 10px;

  ${props =>
    props.listType === 'newline' &&
    `
    margin-left: 30px;
    margin-right: 0;
  `}
`;

const PromoSelection = styled(Checkbox).attrs({ type: 'radio', name: 'promo' })`
  display: inline;
  margin-right: 10px;

  > div {
    vertical-align: text-bottom;
  }
`;

const ViewPromoDetail = styled.a`
  float: right;
  margin-bottom: 20px;
`;

const PricingPromo = ({ price, promoPrice, name, listType }) => (
  <PricingPromoContainer listType={listType}>
    <Field>{`⁃ ${name}:`}</Field>
    <OriginalPrice>
      <CurrencyFormat value={price} displayType="text" thousandSeparator="," />
      {` VNĐ`}
    </OriginalPrice>
    <NewPrice>
      <CurrencyFormat value={promoPrice} displayType="text" thousandSeparator="," />
      {` VNĐ`}
    </NewPrice>
  </PricingPromoContainer>
);

PricingPromo.propTypes = {
  price: PropTypes.number,
  promoPrice: PropTypes.number,
  name: PropTypes.string,
  listType: PropTypes.oneOf(['inline', 'newline']),
};

PricingPromo.defaultProps = {
  price: 0,
  promoPrice: 0,
  name: '',
  listType: 'inline',
};

const PromoList = ({ promos, promoCode, selectable, onSelected, listType }) => {
  return (
    <>
      {_.map(promos, promo => (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={selectable ? null : `/promos/${promo.slug}`} target="_blank">
          <Container
            selectable={selectable}
            onClick={selectable ? () => onSelected(promo.code) || onSelected(promo.code) : null}
          >
            {!selectable && <Bullet>•</Bullet>}
            {selectable && <PromoSelection checked={promo.code === promoCode} />}
            <Title>{promo.title}</Title>
            <Pricing>
              {!_.isNil(promo.pricing12month) && promo.pricing12month >= 0 && (
                <PricingPromo
                  listType={listType}
                  name="12 tháng"
                  price={promo.subscription.pricing12month}
                  promoPrice={promo.pricing12month}
                />
              )}
              {!_.isNil(promo.pricing5month) && promo.pricing5month >= 0 && (
                <PricingPromo
                  listType={listType}
                  name="5 tháng"
                  price={promo.subscription.pricing5month}
                  promoPrice={promo.pricing5month}
                />
              )}
              {!_.isNil(promo.pricing3month) && promo.pricing3month >= 0 && (
                <PricingPromo
                  listType={listType}
                  name="3 tháng"
                  price={promo.subscription.pricing3month}
                  promoPrice={promo.pricing3month}
                />
              )}
            </Pricing>
            {selectable && (
              <>
                <br />
                <ViewPromoDetail href={`/promos/${promo.slug}`} target="_blank">
                  Xem chi tiết
                </ViewPromoDetail>
              </>
            )}
          </Container>
        </a>
      ))}
    </>
  );
};

PromoList.propTypes = {
  promos: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      pricing12month: PropTypes.number,
      pricing3month: PropTypes.number,
      pricing5month: PropTypes.number,
      title: PropTypes.string,
      subscription: PropTypes.shape({
        pricing12month: PropTypes.number,
        pricing3month: PropTypes.number,
        pricing5month: PropTypes.number,
      }),
    })
  ),
  promoCode: PropTypes.string,
  selectable: PropTypes.bool,
  listType: PropTypes.oneOf(['inline', 'newline']),
  onSelected: PropTypes.func,
};

PromoList.defaultProps = {
  promos: [],
  promoCode: '',
  selectable: false,
  listType: 'inline',
  onSelected: () => {},
};

export default PromoList;
