import { request } from '../utils/http';

export function getSubscriptions() {
  return request({
    apiVersion: '1.3',
    url: '/subscriptions',
    method: 'GET',
  });
}

export function getMySubscriptions() {
  return request({
    url: '/subscriptions/me',
    method: 'GET',
    authRequired: true,
  });
}

export function purchaseSubscription(data) {
  return request({
    url: '/subscriptions/purchase',
    method: 'POST',
    data,
    authRequired: true,
  });
}
