import jwtDecode from 'jwt-decode';
import _ from 'lodash';

import { get } from './storage';

export const AUTH_TOKEN_KEY = 'auth.token';

function decodeToken(token) {
  const authToken = token || get(AUTH_TOKEN_KEY);

  if (!authToken) {
    return null;
  }

  try {
    return jwtDecode(authToken);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function isAuthenticated() {
  const payload = decodeToken();

  if (!payload) {
    return false;
  }

  return (
    new Date(payload.exp * 1000) >= Date.now() && payload.profile && payload.profile.permissions
  );
}

export function getUserProfile() {
  const payload = decodeToken();

  if (!payload) {
    return null;
  }

  return payload.profile;
}

export function hasPermission(permission) {
  const payload = decodeToken();

  if (!payload) {
    return null;
  }

  if (payload.profile && payload.profile.permissions) {
    return _.indexOf(payload.profile.permissions, permission) > -1;
  }

  return false;
}
