import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { math } from 'polished';

import useAuth from '../../utils/useAuth';
import { hideAt } from '../../utils/responsive';
import breakpoints from '../../styles/breakpoints';
import Avatar from '../Avatar';
import Dropdown from '../Dropdown';
import Menu from '../Menu';

const SmallScreen = hideAt({ min: math(`${breakpoints.sm} + 1px`) });
const BigScreen = hideAt({ max: breakpoints.sm });

const StyledDropdown = styled(Dropdown).attrs({
  triggerClassName: 'trigger',
  menuClassName: 'menu',
})`
  margin-left: 1rem;

  .trigger {
    height: auto;
  }

  .menu {
    top: 52px;

    @media (max-width: ${breakpoints.sm}) {
      top: 42px;
    }
  }
`;

const UserInfo = styled.div`
  padding: 14px 14px 20px 14px;
  border-bottom: 1px solid #f6f6f6;
`;

const UserName = styled.div`
  font-size: 0.875rem;
`;

const UserEmail = styled.div`
  font-size: 0.75rem;
  color: #919bb0;
`;

function UserDropdown({ avatarSize, ...rest }) {
  const { user, logout } = useAuth();

  if (!user) {
    return null;
  }

  const userName = user.name ? `${user.name.first} ${user.name.last}` : '';

  const trigger = (
    <>
      <BigScreen>
        <Avatar url={user.picture} name={userName} email={user.email} size={avatarSize} />
      </BigScreen>
      <SmallScreen>
        <Avatar url={user.picture} name={userName} email={user.email} size={40} />
      </SmallScreen>
    </>
  );

  return (
    <StyledDropdown trigger={trigger} position="right" {...rest}>
      <UserInfo>
        <UserName>{userName}</UserName>
        <UserEmail>{user.email}</UserEmail>
      </UserInfo>

      <Menu>
        <Menu.Item as={Link} to="/user/my-videos">
          Video của tôi
        </Menu.Item>
        <Menu.Item as={Link} to="/user/profile">
          Tài khoản
        </Menu.Item>
        <Menu.Item danger onClick={logout}>
          Đăng xuất
        </Menu.Item>
      </Menu>
    </StyledDropdown>
  );
}

UserDropdown.propTypes = {
  avatarSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

UserDropdown.defaultProps = {
  avatarSize: 44,
};

export default UserDropdown;
