import { request } from '../utils/http';

export function getCommon() {
  return request({
    url: '/common',
    method: 'GET',
  });
}

export function submitContact(data) {
  return request({
    url: '/contact',
    method: 'POST',
    data,
  });
}

export function forgotPassword(data) {
  return request({
    url: '/recover-password',
    method: 'POST',
    data,
  });
}

export function getAllBlogs() {
  return request({
    url: '/blogs',
    method: 'GET',
  });
}

export function getAllPromos() {
  return request({
    url: '/promos',
    method: 'GET',
  });
}
