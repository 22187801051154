import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SIZE_MAP = {
  small: 20,
  default: 42,
  large: 66,
  xLarge: 87,
};

const FONT_SIZE_MAP = {
  small: '0.625rem',
  default: '1.2rem',
  large: '2rem',
  xLarge: '2.8rem',
};

function getNameAbbr(name) {
  if (!name) {
    return '';
  }

  let abbr = '';
  const words = name.split(' ');

  if (words.length === 1) {
    [abbr] = words;
  } else {
    abbr = words.map(word => word.charAt(0)).join('');
  }

  return abbr.substr(0, 2).toUpperCase();
}

function getSize(size) {
  if (typeof size === 'number') {
    return `${size}px`;
  }

  return `${SIZE_MAP[size] || SIZE_MAP.default}px`;
}

function getFontSize(size) {
  if (typeof size === 'number') {
    return `${size / 30}rem`;
  }

  return FONT_SIZE_MAP[size] || FONT_SIZE_MAP.default;
}

const Wrapper = styled.figure`
  display: inline-block;
  position: relative;
  width: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
  margin: 0;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  color: #ffffff;
  background-color: #d9d7e0;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 1s;

  &[loaded] {
    opacity: 1;
  }
`;

const Name = styled.figcaption`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: ${({ size }) => getFontSize(size)};
  transform: translate(-50%, -50%);
`;

function Avatar({ url, name, email, size, ...rest }) {
  const handleImageLoaded = event => {
    event.target.setAttribute('loaded', '');
  };

  return (
    <Wrapper size={size} {...rest}>
      <Name size={size}>{getNameAbbr(name) || getNameAbbr(email)}</Name>
      <AvatarImage src={url} alt={name} onLoad={handleImageLoaded} />
    </Wrapper>
  );
}

Avatar.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Avatar.defaultProps = {
  url: '',
  name: '',
  email: '',
  size: 'default',
};

export default Avatar;
