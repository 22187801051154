import { request } from '../utils/http';

export function getVideoQuestions(videoCode) {
  return request({
    apiVersion: '1.2',
    url: `/questions/video/${videoCode}`,
    method: 'GET',
    authRequired: true,
  });
}
